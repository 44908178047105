import { actionEnum } from '../../Enums/actionEnum';

const selectedGift = (state = 0, action) => {
    switch(action.type) {
        case actionEnum.SELECT_FROM_AVAILABLE_GIFTS:;
            let value = parseInt(action.payload.value);
            return value;
        default:
            return state;
    }
}

export default selectedGift;