import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import allActions from '../../../../../Services/Store/Actions';
import { actionEnum } from '../../../../../Services/Enums/actionEnum';

import Cross from "../../../../../../img/cross.svg";
import Progress from '../../../../../Services/Models/Progress';
import Loader from '../Loader';
import { useTranslation } from 'react-i18next';
import completePurchase from '../../../../../Services/Store/Reducers/_completePurchase';
export default function ErrorBody() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [t, i18n] = useTranslation();

    return (<div className="summary__container summary__error">
        <img src={Cross} alt="ERROR!" />
        <div className="container__title_error">{t('Main.Summary.ErrorTitle')}</div>


    </div>)

}