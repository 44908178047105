import giftActions from './_giftActions';
import formActions from './_formActions';
import propertyActions from './_propertyActions';

const allActions = {
    formActions,
    giftActions,
    propertyActions
}

export default allActions;