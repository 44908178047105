const setGift = (type, value) => {
    return {
        type: type,
        payload: {
            value
        }
    }
}

export default {
    setGift
}