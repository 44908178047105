import React from 'react';

import Form from './Form';
import Error from './Error';
import allActions from '../../Services/Store/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Container() {
    //const slug = useSelector(state => state.currentProperty);
    let { slug } = useParams();

 
    const [t, i18n] = useTranslation();
   
    const dispatch = useDispatch();
 
    dispatch(allActions.propertyActions.setCurrentProperty(slug));  

    if (slug) {
        dispatch(allActions.propertyActions.getPropertySettings(slug));
        dispatch(allActions.giftActions.getGiftcardValues(slug));

    }   

    return (
        <React.Fragment>      
            <Form />
        </React.Fragment>
    )  
}