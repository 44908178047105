import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import allActions from '../../../../../Services/Store/Actions';
import { actionEnum } from '../../../../../Services/Enums/actionEnum';

import FormGift from './FormGift';
import Progress from '../../../../../Services/Models/Progress';


export default function Data() {
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(allActions.formActions.setGift(actionEnum.SET_PROGRESS_BAR, new Progress(50, 2)));
    }, []);

    return (
        <div className="main__data">
            <FormGift />
        </div>
    )
}
