import { actionEnum } from '../../Enums/actionEnum';
import { targetEnum } from '../../Enums/targetEnum';
import Gift from '../../Models/Gift';

const formGift = (state = new Gift(), action) => {
    let gift = state;
    switch (action.type) {
        case actionEnum.SET_COMPANY_ADDRESS_CITY:
            gift.companyCity = action.payload.value;
            return gift;
        case actionEnum.SET_COMPANY_ADDRESS_COUNTRY:
            gift.companyCountry = action.payload.value;
            return gift;
        case actionEnum.SET_COMPANY_ADDRESS_NUMBER:
            gift.companyNumber = action.payload.value;
            return gift;
        case actionEnum.SET_COMPANY_ADDRESS_POSTAL:
            gift.companyPostal = action.payload.value;
            return gift;
        case actionEnum.SET_COMPANY_ADDRESS_STREET:
            gift.companyStreet = action.payload.value;
            return gift;
        case actionEnum.SET_COMPANY_NAME:
            gift.companyName = action.payload.value;
            return gift;
        case actionEnum.SET_COMPANY_VAT:
            gift.companyVAT = action.payload.value;
            return gift;
        case actionEnum.SET_EMAIL:
            gift.customerEmail = action.payload.value;
            return gift;
        case actionEnum.SET_FIRST_NAME:
            gift.customeFirstName = action.payload.value;
            return gift;
        case actionEnum.SET_INVOICE:
            gift.asksInvoice = action.payload.value;
            return gift;
        case actionEnum.SET_LAST_NAME:
            gift.customerName = action.payload.value;
            return gift;
        case actionEnum.SET_TARGET:
            gift.target = action.payload.value;        
            return gift;
        case actionEnum.SET_TARGET_MAIL:
            gift.mailtarget = action.payload.value;
            gift.hasReceiver = gift.mailtarget !== targetEnum.Myself
            return gift;
        case actionEnum.SET_TARGET_EMAIL:
            gift.receiverEmail = action.payload.value;
            return gift;
        case actionEnum.SET_TARGET_FIRST_NAME:
            gift.receiverFirstName = action.payload.value;
            return gift;
        case actionEnum.SET_TARGET_LAST_NAME:
            gift.receiverName = action.payload.value;
            return gift;
        case actionEnum.SET_TARGET_MESSAGE:
            gift.receiverMemo = action.payload.value;
            return gift;
        case actionEnum.SET_MESSAGE:
            gift.message = action.payload.value;
            return gift;
        case actionEnum.SET_TERMS_AND_CONDITIONS:
            gift.acceptTermsAndConditions = action.payload.value;
            return gift;
        case actionEnum.SET_SLUG:
            gift.slug = action.payload.value;
            return gift;      
        case actionEnum.SET_VALUES:
            gift.values = action.payload.value;
            return gift; 
        case actionEnum.SET_CUSTOMER_CITY:
            gift.customerCity = action.payload.value;
            return gift;
        case actionEnum.SET_CUSTOMER_PHONE:
            gift.customerPhone = action.payload.value;
            return gift;
        case actionEnum.SET_CUSTOMER_STREET:
            gift.customerStreet = action.payload.value;
            return gift;
        case actionEnum.SET_CUSTOMER_NUMBER:
            gift.customerNumber = action.payload.value;
            return gift;
        case actionEnum.SET_CUSTOMER_POSTAL:
            gift.customerPostal = action.payload.value;
            return gift;
        case actionEnum.SET_LANG:
            gift.lang = action.payload.value;
            return gift;
        default:
            return state;
    }
} 

export default formGift;