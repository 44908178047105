import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { actionEnum } from '../../../../../Services/Enums/actionEnum';
import allActions from '../../../../../Services/Store/Actions';

import CardsList from './CardsList/CardsList';
import CustomCard from './CustomCard/CustomCard';
import Progress from '../../../../../Services/Models/Progress';
import SubmitButton from '../../../../../Elements/SubmitButton';
import { useTranslation } from 'react-i18next';
export default function Amount() {
    const selectedGift = useSelector(state => state.selectedGift);
    const settings = useSelector(state => state.propertySettings);
    const [t, i18n] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { slug } = useParams();

    useEffect(() => {
        dispatch(allActions.formActions.setGift(actionEnum.SET_PROGRESS_BAR, new Progress(7, 1)));
    }, []);

    const card__select = () => {
        let selectedValue = selectedGift;
        if (selectedValue === '' || typeof selectedValue !== 'number' || isNaN(selectedValue) || selectedValue <= 0) {
            let value = parseInt(document.getElementById('custom__value').value);
            if (value === '' || typeof value !== 'number' || isNaN(value) || value <= 0 || value < 25 || value > 250) return;
            selectedValue = value;
        }

        dispatch(allActions.giftActions.addToCurrentGifts(selectedValue));
        dispatch(allActions.giftActions.selectGift(0));

        history.push(`${slug}/data`);

    };

    const valuesBody = () => {
        if (settings.isActive) {
            return (
                <React.Fragment>
                    <h2>{t('Main.Amount.PickAnAmount')}</h2>
                    <CardsList />
                    <CustomCard />
                    <SubmitButton text={t('Main.Amount.OrderYourGiftcard')} click={card__select} />
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <h2>{t('Main.Amount.ModuleNotActive')}</h2>
                </React.Fragment>
            );
        }
    };

    return (
        <div className="main__amount">
            {valuesBody()}
        </div>
    )
}
