import { actionEnum } from '../../Enums/actionEnum';
import giftCardAPI from '../../API/giftCardAPI';

const getPropertySettings = (slug) => async (dispatch) => {
    const response = await giftCardAPI.get(`/api/Property/Settings/${slug}`);

    if (response.data) {
        dispatch({
            type: actionEnum.GET_PROPERTY_SETTINGS,
            payload: response.data,
        })
    }
    else {
        dispatch({
            type: actionEnum.GET_PROPERTY_SETTINGS,
            payload: [],
        })
    } 


    dispatch({
        type: actionEnum.LOADING,
        payload: false,

    })
};

const loading = (state) => async (dispatch) => {
    dispatch({
        type: actionEnum.LOADING,
        payload: state,
    })
}

const setCurrentProperty = (slug) => async (dispatch) => {
    const doesSlugExist = await giftCardAPI.post(`/api/Property/Settings/${slug}`);
   
    if (doesSlugExist.data) {
        dispatch({
            type: actionEnum.SELECT_CURRENT_PROPERTY,
            payload: slug,
        })
    }
    else {
        dispatch({
            type: actionEnum.SELECT_CURRENT_PROPERTY,
            payload: "",
        })
    }
  
};

export default {
    getPropertySettings,
    setCurrentProperty,
    loading
}