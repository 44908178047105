import { actionEnum } from '../../Enums/actionEnum'


const propertySettings = (state = {}, action) => {
    switch (action.type) {
        case actionEnum.GET_PROPERTY_SETTINGS:            
            return action.payload;
        default:
            return state;
    }
}





export default propertySettings;