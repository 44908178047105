import { actionEnum } from '../../Enums/actionEnum'

const paymentData = (state = {}, action) => {
    switch (action.type) {
        case actionEnum.SAVE_TEMP_PURCHASE:
            return action.payload;
        default:
            return state;
    }
}



export default paymentData;