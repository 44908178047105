import { actionEnum } from '../../Enums/actionEnum';


const completePurchase = (state = null, action) => {
        switch(action.type) {
            case actionEnum.COMPLETE_PURCHASE:
        return action.payload;
            default:
        return state;
    }
}

export default completePurchase;