import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
export default function Dropdown(props) {
    const [filter, setFilter] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState(null);
    const [selectedItem, setSelectedItem] = useState('');
    const [t, i18n] = useTranslation();
    const dispatch = useDispatch();

    const list = props.list.map((item) => { return { name: item.text, value: item.value } });

    useEffect(() => {
        setOptions(list);
    }, []);

    const filterOptions = (e) => {
        let input = e.target.value.toLowerCase();
        let filtered = list.filter((option) => {
            return option.name.toLowerCase().includes(input);
        });
        setFilter(input);
        setOptions(filtered);
    }

    const openMenu = () => {
        let menu = document.getElementsByClassName('select-search-box__select')[0];
        if (!menu.classList.contains('select-search-box__select--display')) {
            let search = document.getElementsByClassName('select-search-box__search')[0];
            let countryNode = document.getElementsByClassName('country__select')[0];
            hideOnClickOutside(countryNode);
            if (!search.classList.contains('select-search-box__search--selected')) {
                search.classList.add('select-search-box__search--selected');
            }

            menu.classList.add('select-search-box__select--display');
            setIsOpen(true);
        }
    }

    const closeMenu = () => {
        let menu = document.getElementsByClassName('select-search-box__select')[0];
        if (menu.classList.contains('select-search-box__select--display')) {
            let search = document.getElementsByClassName('select-search-box__search')[0];
            if (search.classList.contains('select-search-box__search--selected')) {
                search.classList.remove('select-search-box__search--selected');
            }

            menu.classList.remove('select-search-box__select--display');
            search.value = "";
            setFilter('');
            setIsOpen(false);
            setOptions(list);
        }
    }

    function hideOnClickOutside(el) {
        const outsideClickListener = event => {
            if (!el.contains(event.target) && isVisible(el)) {
                closeMenu();
                removeClickListener();
            }
        }

        const removeClickListener = () => {
            document.removeEventListener('click', outsideClickListener);
        }

        document.addEventListener('click', outsideClickListener);
    }

    const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

    return (
        <div className="country__select">
            <div className="select-search-box select-search-box--select">
                <input type="text" autoComplete="1" placeholder={t('Main.Data.Form.Country.Placeholder')}
                    value={!isOpen ? selectedItem : filter}
                    className="select-search-box__search"
                    onClick={() => openMenu()}
                    onChange={(e) => filterOptions(e)} />
                {(options !== null && options.length > 0) &&
                    <div className="select-search-box__select">
                        <ul className="select-search-box__options">
                            {options.map((item) =>
                                <li key={item.value}
                                    role="menuitem"
                                    className={`select-search-box__option select-search-box__row
                                          ${selectedItem === item.name && ' select-search-box__option--selected'}`}
                                    data-value={item.value}
                                    onClick={() => {
                                        setSelectedItem(item.name);
                                        dispatch(props.action(props.actionName, item.name));
                                        closeMenu();
                                    }}>
                                    {item.name}
                                </li>
                            )}
                        </ul>
                    </div>
                }
            </div>
        </div>
    )
}
