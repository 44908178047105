import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import countries from '../../../../../../../Services/Data/countries';

import { actionEnum } from '../../../../../../../Services/Enums/actionEnum';
import allActions from '../../../../../../../Services/Store/Actions';

import Dropdown from '../../../../../../../Elements/Dropdown';
import { useTranslation } from 'react-i18next';
export default function CompanyForm() {
    const listCountries = countries.map((item) => { return { value: item.code, text: item.name } })
    const [t, i18n] = useTranslation();
    const gift = useSelector(state => state.formGift);
    const dispatch = useDispatch();

    return (
        <div id="company__form" className="company__form">
            <div className="form__group">
                <label>{t('Main.Data.Form.Name.Label')}</label>
                <input type="text" placeholder={t('Main.Data.Form.Name.Placeholder.Company')} className="required-company" defaultValue={gift.companyName}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_COMPANY_NAME, e.target.value))} />
            </div>
            <div className="form__group">
                <label>{t('Main.Data.Form.Vat.Label')}</label>
                <input type="text" placeholder={t('Main.Data.Form.Vat.Placeholder')} className="required-company" defaultValue={gift.companyVAT}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_COMPANY_VAT, e.target.value))} />
            </div>
            <div className="form__group">
                <label>{t('Main.Data.Form.Street.Label')} </label>
                <input type="text" placeholder={t('Main.Data.Form.Street.Placeholder.Company')} className="required-company" defaultValue={gift.companyStreet}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_COMPANY_ADDRESS_STREET, e.target.value))} />
            </div>
            <div className="form__group">
                <label>{t('Main.Data.Form.Number.Label')} </label>
                <input type="text" placeholder={t('Main.Data.Form.Number.Placeholder.Company')} className="required-company" defaultValue={gift.companyNumber}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_COMPANY_ADDRESS_NUMBER, e.target.value))} />
            </div>
            <div className="form__group">
                <label>{t('Main.Data.Form.Postal.Label')} </label>
                <input type="text" placeholder={t('Main.Data.Form.Postal.Placeholder.Company')} className="required-company" defaultValue={gift.companyPostal}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_COMPANY_ADDRESS_POSTAL, e.target.value))} />
            </div>
            <div className="form__group">
                <label>{t('Main.Data.Form.City.Label')} </label>
                <input type="text" placeholder={t('Main.Data.Form.City.Placeholder.Company')} className="required-company" defaultValue={gift.companyCity}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_COMPANY_ADDRESS_CITY, e.target.value))} />
            </div>
            <div className="form__group">
                <label>{t('Main.Data.Form.Country.Label')}</label>
                <Dropdown list={listCountries} action={allActions.formActions.setGift} actionName={actionEnum.SET_COMPANY_ADDRESS_COUNTRY} />
            </div>
        </div>
    )
}
