import React from 'react';
import { Switch, Route , Redirect} from 'react-router-dom';

import Amount from './1.Amount';
import Data from './2.Data';
import Summary from './3.Summary';
import CurrentGift from './2.Data/CurrentGift';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
export default function Main() {
    const currentGifts = useSelector(state => state.currentGifts);
    const progress = useSelector(state => state.progress);
    const [t, i18n] = useTranslation();
    return (
        <div className="form__main">
            {((progress.currentStep === 1 && currentGifts.size > 0) ||
                (progress.currentStep !== 1 && progress.currentStep !== 3)) &&
                <CurrentGift />
            }
           
            <Switch>
                <Route
                    path="/:slug*(/+)"
                    exact
                    strict
                    render={({ location }) => (
                        <Redirect to={location.pathname.replace(/\/+$/, "")} />
                    )}
                />
                <Route exact path="/:slug">
                    <Amount />
                </Route>
                <Route exact path="/:slug/data" >               
                  < Data/>
                </Route>
                <Route exact path="/:slug/summary/:id" >
                    <Summary />
                </Route>
            </Switch>
        </div>
    )
}
