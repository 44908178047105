import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../../../../Services/Store/Actions';

export default function Card(props) {
    const selectedGift = useSelector(state => state.selectedGift);

    const dispatch = useDispatch();
    const cardSelect = (value) => {
        document.getElementById('custom__value').value = '';
        if (value === '') return;

        let gift = parseInt(value);
        if (typeof gift !== 'number' || gift <= 0) return;

        dispatch(allActions.giftActions.selectGift(value));
    }

    return (
        <div className={"cards__item card" + (selectedGift === props.value ? " card--selected" : '')} onClick={() => cardSelect(props.value)}>
            <p className="card__currency">{props.currency}</p>&nbsp;<p className="card__value">{props.value}</p>
        </div>
    )
}