import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Header from './1.Header';
import Main from './2.Main';
import Footer from './3.Footer';
import Loader from './2.Main/Loader';

export default function Form() {
    const progress = useSelector(state => state.progress);
    const settings = useSelector(state => state.propertySettings);
    const loading = useSelector(state => state.loading);

    useEffect(() => {
        if (settings.color !== undefined) {
            const head = document.getElementsByTagName('head')[0];
            let style = document.createElement('STYLE');
            style.innerHTML = `
                .custom-scrollbar::-webkit-scrollbar-thumb {
                    background-color: ${settings.color};
                }
            `.trim();
            head.appendChild(style);
        }
        if (settings.backgroundImg !== undefined) {
            document.getElementsByTagName('body')[0].style.background = "url('" + settings.backgroundImg + "') ";         
         }
    })

    return (
        <div className="container__form" >
            {loading ?
                <div className="form__main">
                    <div className="main__summary">
                        <Loader />
                    </div>
                </div>
                :
                <div>
                    {progress.currentStep !== 3 &&
                        <Header />
                    }
                    <Main />
                    <Footer />
                </div>}

           
        </div>
    )

}