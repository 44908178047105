import React from 'react'
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
export default function Modal() {
    const settings = useSelector(state => state.propertySettings);
    const [t, i18n] = useTranslation();
   

    const closeTermsAndConditions = () => {
        const backdrop = document.getElementsByClassName('backdrop')[0];
        const modal = document.getElementsByClassName('modal')[0];

        if (backdrop.classList.contains('is-blacked-out')) {
            backdrop.classList.remove('is-blacked-out');
        }

        if (modal.classList.contains('is-active')) {
            modal.classList.remove('is-active');
        }
    }
            const renderConditions = () => {
                switch (i18n.language) {
                    case "nl":
                        return ReactHtmlParser(settings.conditions.nl);
                    case "fr": 
                        return ReactHtmlParser(settings.conditions.fr);
                    default:
                        return ReactHtmlParser(settings.conditions.en);
                }
            }

    return (
        <div className="modal">
            <div className="modal__container custom-scrollbar gray-scrollbar">
                <h1>{t('Main.Data.Form.TermsConditions')}</h1>
                <div className="modal__content">
                    {settings != [] && settings.conditions !== undefined ?                         
                        <div>{renderConditions()}</div> : <div></div>

                    }
                </div>
                <button onClick={() => closeTermsAndConditions()}>{t('Shared.Close')}</button>
            </div>
        </div>
    )
}
