import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './js/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom"

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './js/Services/Store/Reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { library } from '@fortawesome/fontawesome-svg-core';

import { 
    faTrashAlt as falTrashAlt
} from '@fortawesome/pro-light-svg-icons';

import {
    faAngleRight as fasAngleRight,
    faSortDown as fasSortDown
} from '@fortawesome/pro-solid-svg-icons';

import './i18n';

import loggerMiddleware from './js/Services/Logging/Logger';

library.add(
    falTrashAlt,
    fasAngleRight,
    fasSortDown
);


const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(thunk, loggerMiddleware)
    )
);

ReactDOM.render(
    <Suspense fallback={(<div></div>)}>
        <Provider store={store}>
            <Router>
                <App />
            </Router>,
        </Provider>
    </Suspense>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
