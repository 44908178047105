import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionEnum } from '../../../../Services/Enums/actionEnum';
import allActions from '../../../../Services/Store/Actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import languages from '../../../../Services/Helpers/languages';
import i18n from '../../../../../i18n';
import { useTranslation } from 'react-i18next';
import countries from "i18n-iso-countries";
export default function Header() {
  

    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
    const [t, i18n] = useTranslation();
    const steps = [t('Header.Steps.Amount'), t('Header.Steps.YourData'), t('Header.Steps.Payment')];
    const dispatch = useDispatch();
    const settings = useSelector(state => state.propertySettings);
    const progress = useSelector(state => state.progress);

    const options = languages.map(language => {
        return <option value={language} selected={i18n.language == language ? true : false} key={language}>{language.toUpperCase()}</option>
    });

    dispatch(allActions.formActions.setGift(actionEnum.SET_LANG, i18n.language));
    function handleChange(e) {
        i18n.changeLanguage(e.target.value);
        dispatch(allActions.formActions.setGift(actionEnum.SET_LANG, e.target.value));
    }

    const url = `https://www.younight.com/${i18n.language}`;
    return (
        <header className="form__header">
            <div className="header__intro">
                <div className="intro__title">
                    <h1><a href={url} className="header__url"><span className="header__back">&#8249;</span></a>{settings.propertyName}</h1>
                    <div className="title__language">
                        <select className="selectlanguage" onChange={(e) => handleChange(e)}>
                            {options}
                        </select>
                    </div>
                </div>

                {
                    settings.company != undefined && settings.company != {} ? 
                        <div className="intro__subtitle intro__address">
                            <h4>{settings.company.street} {settings.company.number}, {settings.company.postal} {settings.company.city}, {countries.getName(settings.company.country, i18n.language)}</h4>
                        </div>
                        :
                        <div></div>
                }

             
                <div className="intro__subtitle">
                    <h3 dangerouslySetInnerHTML={{ __html: t('Header.OrderYourGiftcardFor') }}></h3>
                </div>
            </div>
            <div className="header__progress">
                <div className="progress__bar">
                    <span style={{ width: `${progress.width}%` }}>
                        <span className="bar" style={{ backgroundColor: settings.color }}></span>
                    </span>
                </div>
                <div className="progress__steps">
                    {steps.map((step, i) => {
                        if (progress.currentStep === i + 1) {
                            return <span key={i}><b>{`${i + 1}. ${step}`}</b></span>
                        } else {
                            return <span key={i}>{`${i + 1}. ${step}`}</span>
                        }
                    }
                    )}
                </div>
            </div>
        </header >
    )
}