import Company from './Company';
import Customer from './Customer';
import Receiver from "./Receiver";

import { targetEnum } from '../Enums/targetEnum';

export default class Gift {
    slug= "";   
    customerName= "";
    customeFirstName= "";
    customerEmail= "";
    customerPhone= "";
    customerStreet= "";
    customerNumber= "";
    customerBox= "";
    customerPostal= "";
    customerCity= "";
    receiverEmail= "";
    receiverName= "";
    receiverFirstName= "";
    receiverMemo= "";
    values = [];
    asksInvoice= false;
    companyVAT= "";
    companyName= "";
    companyStreet= "";
    companyNumber= "";
    companyCity= "";
    companyPostal= "";
    companyCountry = "";
    companyPostal = "";
    hasReceiver = false;
    lang = "en";   
    target = targetEnum.Myself; 
    mailtarget = targetEnum.Myself; 
    acceptTermsAndConditions = false;
    message = "";
}