import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SelectedGift from './SelectedGift/SelectedGift'

import { currencyEnum } from '../../../../../../Services/Enums/currencyEnum';
import allActions from '../../../../../../Services/Store/Actions';
import { useTranslation } from 'react-i18next';

export default function CurrentGift() {
    const [isOpen, setIsOpen] = useState(false);

    const currentGifts = useSelector(state => state.currentGifts);
    const currentStep = useSelector(state => state.progress.currentStep);
    const totalCost = useSelector(state => state.totalCost);
    const [t, i18n] = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

  

    const { slug } = useParams();
    useEffect(() => {
        init();
        history.listen(() => {
            if (isOpen) closeCustomisation();
        });
    });

    const init = () => {
        dispatch(allActions.giftActions.setTotalGift(currentGifts));

        if (currentGifts.size <= 0) {
            history.push(`/${slug}`);
        }
    }  

    const addGift = () => {
        toggleCustomisation();
        history.push(`/${slug}`);
    }

    const toggleCustomisation = () => {
        if (isOpen) {
            closeCustomisation();
        } else {
            openCustomisation();
        }
    }

    const closeCustomisation = () => {
        let content = document.getElementById('gift__customisation');
        let arrowButton = document.getElementById('action__arrow');
        if (content !== null && arrowButton !== null) {
            arrowButton.classList.remove('action__arrow--open');
            content.style.maxHeight = null;
            setIsOpen(false);
        }
    }

    const openCustomisation = () => {
        let content = document.getElementById('gift__customisation');
        let arrowButton = document.getElementById('action__arrow');
        if (content !== null && arrowButton !== null) {
            arrowButton.classList.add('action__arrow--open')
            content.style.maxHeight = content.scrollHeight + "px";
            setIsOpen(true);
        }
    }

    return (
        <div className="main__current-gift">
            <div className="gift__header" onClick={toggleCustomisation}>
                <div className="header__amount">
                    {t('Main.Data.CurrentGift.YourOrder')} ({currentGifts.size})
                </div>
                <div className="header__total">
                    {t('Shared.Total')}: {`${currencyEnum.Euro} ${totalCost}`}
                </div>
                <div className="header__action">
                    <FontAwesomeIcon id="action__arrow" className="action__arrow" icon={["fas", "angle-right"]} />
                </div>
            </div>
            <div id="gift__customisation" className="gift__customisation">
                <hr />
                {(currentGifts.size > 0 &&
                    <div className="customisation__cards">
                        {
                        [...currentGifts].map(([key, value]) =>
                            <SelectedGift key={key} currency={currencyEnum.Euro} amount={value} value={key} isLast={currentGifts.size > 1} />
                            )
                        }
                    </div>
                )}
                <div className="customisation__add">
                    {currentStep !== 1 &&
                        <span onClick={addGift}>+ {t('Main.Data.CurrentGift.AddAnotherGiftcard')}</span>
                    }
                </div>
            </div>
        </div>
    )
}
