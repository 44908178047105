import React from 'react';
import { useSelector } from 'react-redux';
export default function SubmitButton(props) {

    const settings = useSelector(state => state.propertySettings);
   
    return (
        <button className="submit" onClick={() => props.click()} style={{ backgroundColor: settings.color }}>{props.text}</button>
    )
}
