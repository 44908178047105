import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import allActions from '../../../../../Services/Store/Actions';
import { actionEnum } from '../../../../../Services/Enums/actionEnum';

import Check from "../../../../../../img/check.svg";
import Progress from '../../../../../Services/Models/Progress';
import Loader from '../Loader';
import { useTranslation } from 'react-i18next';
import completePurchase from '../../../../../Services/Store/Reducers/_completePurchase';
import PaidBody from './PaidBody';
import PendingBody from './PendingBody';
import { paymentEnum } from '../../../../../Services/Enums/paymentEnum';
import ErrorBody from './ErrorBody';
export default function Summary() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const completePurchases = useSelector(state => state.completePurchase);
    const settings = useSelector(state => state.propertySettings);
    const [t, i18n] = useTranslation();
    useEffect(() => {
        dispatch(allActions.formActions.setGift(actionEnum.SET_PROGRESS_BAR, new Progress(100, 3)));
        if (id !== undefined && completePurchases === null) {
            dispatch(allActions.giftActions.completePurchase(id));
        }
    }, []);

    const renderContent = () => {
       
        if (completePurchases.status === paymentEnum.PAID)
         return (<PaidBody/>)

        if (completePurchases.status == paymentEnum.PENDING)
            return (<PendingBody />) 

        return(<ErrorBody/>)
            
    }
    return (
        <div className="main__summary">
            {completePurchases !== null ?            
                  renderContent()                 
                : 
                <Loader />
            }
        </div>
    )
}
