export const actionEnum = {
    LOADING: 'LOADING',
    ADD_TO_CURRENT_GIFTS: 'ADD_TO_CURRENT_GIFTS',
    DECREMENT_CURRENT_GIFT: 'DECREMENT_CURRENT_GIFT',
    GET_PROPERTY_SETTINGS: 'GET_PROPERTY_SETTINGS',
    GET_VALUES: 'GET_VALUES',
    INCREMENT_CURRENT_GIFT: 'INCREMENT_CURRENT_GIFT',
    REMOVE_FROM_CURRENT_GIFTS: 'REMOVE_FROM_CURRENT_GIFTS',
    SELECT_CURRENT_PROPERTY: 'SELECT_CURRENT_PROPERTY',
    SELECT_FROM_AVAILABLE_GIFTS: 'SELECT_FROM_AVAILABLE_GIFTS',
    SET_COMPANY_ADDRESS_CITY: 'SET_COMPANY_ADDRESS_CITY',
    SET_COMPANY_ADDRESS_COUNTRY: 'SET_COMPANY_ADDRESS_COUNTRY',
    SET_COMPANY_ADDRESS_NUMBER: 'SET_COMPANY_ADDRESS_NUMBER',
    SET_COMPANY_ADDRESS_POSTAL: 'SET_COMPANY_ADDRESS_POSTAL',
    SET_COMPANY_ADDRESS_STREET: 'SET_COMPANY_ADDRESS_STREET',
    SET_COMPANY_NAME: 'SET_COMPANY_NAME',
    SET_COMPANY_VAT: 'SET_COMPANY_VAT',
    SET_EMAIL: 'SET_EMAIL',
    SET_FIRST_NAME: 'SET_FIRST_NAME',
    SET_INVOICE: 'SET_INVOICE',
    SET_LAST_NAME: 'SET_LAST_NAME',
    SET_PROGRESS_BAR: 'SET_PROGRESS_BAR',
    SET_PROGRESS_BAR_WIDTH: 'SET_PROGRESS_BAR_WIDTH',
    SET_TARGET: 'SET_TARGET',
    SET_TARGET_EMAIL: 'SET_TARGET_EMAIL',
    SET_TARGET_FIRST_NAME: 'SET_TARGET_FIRST_NAME',
    SET_TARGET_LAST_NAME: 'SET_TARGET_LAST_NAME',
    SET_TARGET_MESSAGE: 'SET_TARGET_MESSAGE',
    SET_MESSAGE: 'SET_MESSAGE',
    SET_TERMS_AND_CONDITIONS: 'SET_TERMS_AND_CONDITIONS',
    SET_TOTAL_COST: 'SET_TOTAL_COST',  
    SET_SLUG: 'SET_SLUG',
    SET_VALUES: 'SET_VALUES',
    SAVE_TEMP_PURCHASE: 'SAVE_TEMP_PURCHASE',
    COMPLETE_PURCHASE: 'COMPLETE_PURCHASE',
    SET_CUSTOMER_PHONE: '  SET_CUSTOMER_PHONE',
    SET_CUSTOMER_STREET: '  SET_CUSTOMER_STREET',
    SET_CUSTOMER_NUMBER: '  SET_CUSTOMER_NUMBER',
    SET_CUSTOMER_POSTAL: '  SET_CUSTOMER_POSTAL',
    SET_CUSTOMER_CITY: '  SET_CUSTOMER_CITY',
    GET_LANGUAGES: 'GET_LANGUAGES',
    SET_LANG: 'SET_LANG',
    SET_TARGET_MAIL: 'SET_TARGET_MAIL'
}