import React from 'react';
import { useDispatch } from 'react-redux';

import allActions from '../../../../../../Services/Store/Actions';

import { currencyEnum } from '../../../../../../Services/Enums/currencyEnum.js';
import { useTranslation } from 'react-i18next';
export default function CustomCard() {
    const dispatch = useDispatch();
    const [t, i18n] = useTranslation();
    const cardReset = () => {
        dispatch(allActions.giftActions.selectGift(0));
    };

    return (
        <div className="amount__custom">
            <div className="custom__currency">
                <p>{currencyEnum.Euro}</p>
            </div>
            <input id="custom__value"  className="custom__value" onClick={() => cardReset()} placeholder={t('Main.Amount.CustomCard.EnterYourValue')}></input>
        </div>
    )
}
