import React from 'react';
import { useSelector } from 'react-redux';

import Card from './Card';

import { currencyEnum } from '../../../../../../Services/Enums/currencyEnum';

export default function CardsList() {
    const values = useSelector(state => state.giftCardValues);

    const renderList = values.map((value, index) => {
        if (index % 3 === 0 && index > 0) {
            return (
                <div className="newLine">
                    <div className="break"></div>
                    <Card currency={currencyEnum.Euro} value={value} key={index} />
                   </div>
                )
        }
        return <Card currency={currencyEnum.Euro} value={value} key={index} />
    });

    return (
        <div className="amount__cards" >
            {values !== undefined && values.length > 0 &&  renderList }                            
        </div>
    );

}

