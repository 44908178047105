import { actionEnum } from '../../Enums/actionEnum';

const loading = (state = true, action) => {
    switch (action.type) {
        case actionEnum.LOADING:
            return action.payload;
        default:
            return state;
    }
}

export default loading;