import { actionEnum } from '../../Enums/actionEnum';

const currentProperty = (state = "", action) => {
    switch (action.type) {
        case actionEnum.SELECT_CURRENT_PROPERTY:          
            return action.payload;
        default:
            return state;
    }
}

export default currentProperty;