const availableGifts = (state = [], action) => {
    switch(action.type) {
        case "ADD":
            let addArray = state.slice();
            addArray.push(action.payload);
            return addArray;
        default:
            return [];
    }
}

export default availableGifts;