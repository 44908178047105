import { actionEnum } from '../../Enums/actionEnum';
import giftCardAPI from '../../API/giftCardAPI';

let previousId = 0;

const addToCurrentGifts = (value) => {
    const id = previousId + 1;
    const action = {
        type: actionEnum.ADD_TO_CURRENT_GIFTS,
        payload: {
            id, value
        }
    };
    previousId = id;
    return action;

}

const decrementCurrentGift = (value) => {
    return {
        type: actionEnum.DECREMENT_CURRENT_GIFT,
        payload: {
            value
        }
    }
}

const getGiftcardValues = (slug) => async (dispatch) => {
    const response = await giftCardAPI.get(`/api/GiftCard/Property/slug/${slug}`); 

    if (response.data) {
        dispatch({
            type: actionEnum.GET_VALUES,
            payload: response.data.values,

        })
    }
    else {
        dispatch({
            type: actionEnum.GET_VALUES,
            payload: [],

        })
    }
 
};

const saveTempPurchase = (tempPurchase) => async (dispatch) => {
    
    const response = await giftCardAPI.post(`/api/GiftCard/Purchase/Temp`, tempPurchase);   

    dispatch({
        type: actionEnum.SAVE_TEMP_PURCHASE,
        payload: response.data,
    })
}

const completePurchase = (tempId) => async (dispatch) => {  
    const response = await giftCardAPI.post(`/api/GiftCard/Purchase/Complete/${tempId}`);

    dispatch({
        type: actionEnum.COMPLETE_PURCHASE,
        payload: response.data,
    })
}

const incrementCurrentGift = (value) => {
    return {
        type: actionEnum.INCREMENT_CURRENT_GIFT,
        payload: {
            value
        }
    }
}

const removeFromSelectedGifts = (value) => {
    return {
        type: actionEnum.REMOVE_FROM_CURRENT_GIFTS,
        payload: {
            value
        }
    }
}

const selectGift = (value) => {
    return {
        type: actionEnum.SELECT_FROM_AVAILABLE_GIFTS,
        payload: {
            value
        }
    }
}

const setTotalGift = (value) => {
    return {
        type: actionEnum.SET_TOTAL_COST,
        payload: {
            value
        }
    }
}

export default {
    addToCurrentGifts,
    decrementCurrentGift,
    getGiftcardValues,
    incrementCurrentGift,
    removeFromSelectedGifts,
    selectGift,
    setTotalGift,
    saveTempPurchase,
    completePurchase
}