
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import allActions from '../../../../../Services/Store/Actions';
import { actionEnum } from '../../../../../Services/Enums/actionEnum';

import Check from "../../../../../../img/check.svg";
import Progress from '../../../../../Services/Models/Progress';
import Loader from '../Loader';
import { useTranslation } from 'react-i18next';
import completePurchase from '../../../../../Services/Store/Reducers/_completePurchase';
export default function PendingBody() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [t, i18n] = useTranslation();

    return (<div className="summary__container summary__success">
        <img src={Check} alt="Success!" />
        <div className="container__title">{t('Main.Summary.PendingTitle')}</div>
        <div className="container__message" dangerouslySetInnerHTML={{ __html: t('Main.Summary.PendingBody') }}>
        </div>

    </div>)
}

