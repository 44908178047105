import { calculateTotalAmount } from '../../Helpers/calculator';

import { actionEnum } from '../../Enums/actionEnum';

const totalCost = (state = 0, action) => {
    let cost = state;
    switch (action.type) {
        case actionEnum.SET_TOTAL_COST:
            if (action.payload.value instanceof Map) {
                cost = calculateTotalAmount(action.payload.value);
            }
            return cost;
        default:
            return state;
    }
}

export default totalCost;