import React, { Component } from 'react';
import './App.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,  
} from "react-router-dom";

import Container from './Components/Container';
import Error from './Components/Container/Error';
import Modal from './Elements/Modal';
import { useDispatch, useSelector } from 'react-redux';
import allActions from './Services/Store/Actions';
export default function App() {

    const dispatch = useDispatch();

    dispatch(allActions.propertyActions.loading(true));  

    const closeTermsAndConditions = () => {
        const backdrop = document.getElementsByClassName('backdrop')[0];
        const modal = document.getElementsByClassName('modal')[0];

        if (backdrop.classList.contains('is-blacked-out')) {
            backdrop.classList.remove('is-blacked-out');
        }

        if (modal.classList.contains('is-active')) {
            modal.classList.remove('is-active');
        }
    }
    
    return (
        <Router>
            <div className="backdrop" onClick={() => closeTermsAndConditions()}></div>
            <Modal />
            <Switch>
                <Route path="/:slug" strict={false}>
                  <Container/>
              </Route>
                <Route exact path="/" strict={false}>
                  <Error />
              </Route>
            </Switch>  
        </Router>
    )
}
