import { actionEnum } from '../../Enums/actionEnum';

import Progress from '../../Models/Progress';

const progress = (state = new Progress(7,1), action) => {
    let progress = state;
    switch (action.type) {
        case actionEnum.SET_PROGRESS_BAR:
            progress = action.payload.value;
            return progress;
        case actionEnum.SET_PROGRESS_BAR_WIDTH:
            progress.width = action.payload.value;
            return progress;
        default:
            return state;
    }
}

export default progress;