import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { targetEnum } from '../../../../../../Services/Enums/targetEnum';
import { actionEnum } from '../../../../../../Services/Enums/actionEnum';
import allActions from '../../../../../../Services/Store/Actions';

import CompanyForm from './CompanyForm';
import SubmitButton from '../../../../../../Elements/SubmitButton';
import { loadStripe } from "@stripe/stripe-js";
import { fal } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
export default function FormGift() {
    const [target, setTarget] = useState(targetEnum.Myself);
    const [invoice, setInvoice] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [t, i18n] = useTranslation();
    const gift = useSelector(state => state.formGift);
    const slug = useSelector(state => state.currentProperty);
    const settings = useSelector(state => state.propertySettings);
    const paymentData = useSelector(state => state.paymentData);
    const dispatch = useDispatch();
    const state = useSelector(state => state);



    const changeTarget = (value) => {
        let content = document.getElementById('target__someone');
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        }
        else {
            content.style.maxHeight = content.scrollHeight + "px";
        }

        setTarget(value);
        dispatch(allActions.formActions.setGift(actionEnum.SET_TARGET, value));
    }

    const changeTargetMail = (value) => {      
        setTarget(value);
        dispatch(allActions.formActions.setGift(actionEnum.SET_TARGET_MAIL, value));
    }

    const getTransitionTime = (el) => {
        let time = window.getComputedStyle(el).transitionDuration;
        if (time.charAt(time.length - 1) === 's' && time.charAt(time.length - 2) !== 'm') {
            time.slice(0, -1);
            time = parseFloat(time) * 1000;
        }

        return parseFloat(time);
    }

    const changeInvoice = () => {
        let content = document.getElementById('company__form');
        if (content.style.maxHeight) {
            content.style.overflow = "hidden";
            content.style.maxHeight = null;
        }
        else {
            const timeout = getTransitionTime(content);
            setTimeout(() => {
                content.style.overflow = "visible";
            }, timeout);
            content.style.maxHeight = content.scrollHeight + "px";
        }

        setInvoice(!invoice);
        dispatch(allActions.formActions.setGift(actionEnum.SET_INVOICE, !gift.asksInvoice));
    }

    const changeTermsAndConditions = () => {
        setTermsAndConditions(!termsAndConditions);
        dispatch(allActions.formActions.setGift(actionEnum.SET_TERMS_AND_CONDITIONS, !gift.acceptTermsAndConditions));
    }

    const validateForm = (fields, validForm) => {

        var requiredFields = document.getElementsByClassName(fields);
        for (var i = 0; i < requiredFields.length; i++) {
            if (requiredFields[i].getAttribute("value") == "") {
                validForm = false;
                requiredFields[i].classList.add("validation-error");
            }
            else {
                requiredFields[i].classList.remove("validation-error");
            }
        }

        return validForm;
    }


    const saveTempPurchase = () => {

        dispatch(allActions.formActions.setGift(actionEnum.SET_SLUG, slug));

        var validForm = true;

        validForm = validateForm("required", validForm);


        if (gift.target === targetEnum.Someone) {
            validForm = validateForm("required-receiver", validForm);
        }

        if (gift.asksInvoice) {
            validForm = validateForm("required-company", validForm);
        }

        if (!gift.acceptTermsAndConditions) {
            validForm = false;
            document.getElementById("terms-and-conditions-label").classList.add("validation-error-text");
        }
        else {
            document.getElementById("terms-and-conditions-label").classList.remove("validation-error-text");
        }

        var values = state.currentGifts;

        var selectedGiftCards = [];
        for (let [k, v] of values) {
            selectedGiftCards.push({
                amount: k,
                quantity: v
            });
        }

        dispatch(allActions.formActions.setGift(actionEnum.SET_VALUES, selectedGiftCards));

        if (validForm) {
            dispatch(allActions.giftActions.saveTempPurchase(gift));
        }

    }

    const openTermsAndConditions = () => {
        const backdrop = document.getElementsByClassName('backdrop')[0];
        const modal = document.getElementsByClassName('modal')[0];

        if (!backdrop.classList.contains('is-blacked-out')) {
            backdrop.classList.add('is-blacked-out');
        }

        if (!modal.classList.contains('is-active')) {
            modal.classList.add('is-active');
        }
    }

    useEffect(() => {
        async function GetPaymentData() {
            if (paymentData !== {}) {

                if (!paymentData.isStripe && paymentData.redirectUrl !== undefined) {
                    window.location.href = paymentData.redirectUrl;
                }

                if (paymentData.sessionId !== undefined && paymentData.apiKey !== undefined && paymentData.isStripe) {
                    const stripePromise = loadStripe(
                        paymentData.apiKey
                    );

                    const stripe = await stripePromise;

                    stripe.redirectToCheckout({
                        // Make the id field from the Checkout Session creation API response
                        // available to this file, so you can provide it as parameter here
                        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                        sessionId: paymentData.sessionId

                    }).then(function (result) {
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer
                        // using `result.error.message`.
                    });
                }
            }
        }
        GetPaymentData();
    });

    return (
        <div className="data__form">
            <h2>{t('Header.Steps.YourData')}</h2>
            <div className="form__group">
                <label>{t('Main.Data.Form.Firstname.Label')}</label>
                <input type="text" className="required" placeholder={t('Main.Data.Form.Firstname.Placeholder.Myself')} defaultValue={gift.customeFirstName}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_FIRST_NAME, e.target.value))} />
            </div>
            <div className="form__group">
                <label>{t('Main.Data.Form.Name.Label')}</label>
                <input type="text" className="required" placeholder={t('Main.Data.Form.Name.Placeholder.Myself')} defaultValue={gift.customerName}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_LAST_NAME, e.target.value))} />
            </div>
            <div className="form__group">
                <label>{t('Main.Data.Form.Email.Label')}</label>
                <input type="text" placeholder={t('Main.Data.Form.Email.Placeholder.Myself')} className="required" defaultValue={gift.customerEmail}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_EMAIL, e.target.value))} />
            </div>
            <div className={settings.askAddress ? "form__group" : "form__group__hidden"}>
                <label>{t('Main.Data.Form.Street.Label')} <span className="small-text">{t('Main.Data.Form.Optional')}</span></label>
                <input type="text" placeholder={t('Main.Data.Form.Street.Placeholder.Myself')} defaultValue={gift.customerStreet}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_CUSTOMER_STREET, e.target.value))} />
            </div>
            <div className={settings.askAddress ? "form__group" : "form__group__hidden"}>
                <label>{t('Main.Data.Form.Number.Label')} <span className="small-text">{t('Main.Data.Form.Optional')}</span></label>
                <input type="text" placeholder={t('Main.Data.Form.Number.Placeholder.Myself')} defaultValue={gift.customerNumber}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_CUSTOMER_STREET, e.target.value))} />
            </div>
            <div className={settings.askAddress ? "form__group" : "form__group__hidden"}>
                <label>{t('Main.Data.Form.Postal.Label')} <span className="small-text">{t('Main.Data.Form.Optional')}</span></label>
                <input type="text" placeholder={t('Main.Data.Form.Postal.Placeholder.Myself')} defaultValue={gift.customerPostal}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_CUSTOMER_POSTAL, e.target.value))} />
            </div>
            <div className={settings.askAddress ? "form__group" : "form__group__hidden"}>
                <label>{t('Main.Data.Form.City.Label')} <span className="small-text">{t('Main.Data.Form.Optional')}</span></label>
                <input type="text" placeholder={t('Main.Data.Form.City.Placeholder.Myself')} defaultValue={gift.customerCity}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_CUSTOMER_CITY, e.target.value))} />
            </div>
            <div className="form__group">
                <label>{t('Main.Data.Form.Message.Label')} <span className="small-text">{t('Main.Data.Form.Optional')}</span></label>
                <textarea placeholder={t('Main.Data.Form.Message.Placeholder')} className="required-receiver" maxLength="200" defaultValue={gift.message}
                    onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_MESSAGE, e.target.value))}></textarea>
            </div>
            <div className="form__group form__target">
                <label className="target__title">{t('Main.Data.Form.WhoReceives')}</label>
                <div>
                    <label className="radio__item">
                        <input type="radio" name="target-gift" value="creator" checked={gift.target === targetEnum.Myself}
                            onChange={() => changeTarget(targetEnum.Myself)} /> {t('Main.Data.Form.Myself')}
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio__item">
                        <input type="radio" name="target-gift" value="other" checked={gift.target === targetEnum.Someone}
                            onChange={() => changeTarget(targetEnum.Someone)} /> {t('Main.Data.Form.SomeoneElse')}:
                        <span className="checkmark"></span>
                    </label>
                    <div id="target__someone" className="target__someone">
                        <label className="radio__item">
                            <input type="radio" name="mail-target-gift" value="creator-mail" checked={gift.mailtarget === targetEnum.Myself}
                                onChange={() => changeTargetMail(targetEnum.Myself)} /> {t('Main.Data.Form.SendMyself')}
                            <span className="checkmark"></span>
                        </label>
                        <label className="radio__item">
                            <input type="radio" name="mail-target-gift" value="other-mail" checked={gift.mailtarget === targetEnum.Someone}
                                onChange={() => changeTargetMail(targetEnum.Someone)} /> {t('Main.Data.Form.SendSomeoneElse')}:
                        <span className="checkmark"></span>
                        </label>
                        <div id="receiver__form">
                        <div className="form__group">
                            <label>{t('Main.Data.Form.Firstname.Label')}</label>
                            <input type="text" placeholder={t('Main.Data.Form.Firstname.Placeholder.Receiver')} className="required-receiver" defaultValue={gift.receiverFirstName}
                                onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_TARGET_FIRST_NAME, e.target.value))} />
                        </div>
                        <div className="form__group">
                            <label>{t('Main.Data.Form.Name.Label')}</label>
                            <input type="text" placeholder={t('Main.Data.Form.Name.Placeholder.Receiver')} className="required-receiver" defaultValue={gift.receiverName}
                                onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_TARGET_LAST_NAME, e.target.value))} />
                        </div>
                        <div className="form__group">
                            <label>{t('Main.Data.Form.Email.Label')}</label>
                            <input type="text" placeholder={t('Main.Data.Form.Email.Placeholder.Receiver')} className="required-receiver" defaultValue={gift.receiverEmail}
                                onBlur={(e) => dispatch(allActions.formActions.setGift(actionEnum.SET_TARGET_EMAIL, e.target.value))} />
                            </div>   
                            </div>
                    </div>
                    </div>
                </div>
         
            <hr />
            <div className="form__group form__conditions">
                <div className="group__checkbox">
                    <input type="checkbox" value="company" checked={gift.asksInvoice} onChange={() => changeInvoice()} />
                    <label onClick={() => changeInvoice()}>{t('Main.Data.Form.AskInvoice')}</label>
                    <CompanyForm />
                </div>
                <div className="group__checkbox">
                    <input type="checkbox" value="terms-and-conditions" className="required-checkbox" checked={gift.acceptTermsAndConditions} onChange={() => changeTermsAndConditions()} />
                    <label id="terms-and-conditions-label"><span onClick={() => changeTermsAndConditions()}>{t('Main.Data.Form.IAccept')} </span><u onClick={() => openTermsAndConditions()}>{t('Main.Data.Form.TermsConditions').toLowerCase()}</u></label>
                </div>
            </div>
            <SubmitButton text={t('Shared.NextStep')} click={saveTempPurchase} />
        </div>
    )
}
