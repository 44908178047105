import { actionEnum } from "../../Enums/actionEnum";

const currentGifts = (state = new Map(), action) => {
    let map = new Map(state);
    switch(action.type) {
        case actionEnum.ADD_TO_CURRENT_GIFTS:
            if (map.has(action.payload.value)) {
                let value = map.get(action.payload.value);
                map.set(action.payload.value, value + 1);
            } else {
                map.set(action.payload.value, 1);
            }
            return map;
        case actionEnum.DECREMENT_CURRENT_GIFT:
            if (map.has(action.payload.value)) {
                let value = map.get(action.payload.value);
                if (value > 1) map.set(action.payload.value, value - 1)
            }
            return map;
        case actionEnum.INCREMENT_CURRENT_GIFT:
            if (map.has(action.payload.value)) {
                let value = map.get(action.payload.value);
                map.set(action.payload.value, value + 1);
            }
            return map;
        case actionEnum.REMOVE_FROM_CURRENT_GIFTS:
            map.delete(action.payload.value);
            return map;
        default:
            return state;
    }
}

export default currentGifts;