import React from 'react';
import { useTranslation } from 'react-i18next';
export default function Footer() {
    const [t, i18n] = useTranslation();

    const url = `https://www.younight.com/${i18n.language}`;
    const stardekkUrl = `https://www.stardekk.com/${i18n.language}`;
    return (
        <footer className="form__footer">
            <p className="footer__text">
                <br />
                <a href={url + "/voucher/conditions"} target="_blank">{t('Main.Data.Form.TermsConditions')}</a>
                <br />
                <br />
                </p>
            <p className="footer__text">
              <b><a target="_blank" href={url}>Younight</a></b> powered by <b><a target="_blank" href={stardekkUrl}>Stardekk &times;</a></b>
            </p>
        </footer>
    )
}

