import { useDispatch, useSelector } from 'react-redux';
import giftCardAPI from '../API/giftCardAPI';
const logger = store => next => action => {
    var log = {
        action: action.type,
        payload: JSON.stringify(action.payload),
        path: window.location.pathname
    };
   
    let result = next(action);     

    giftCardAPI.post(`/api/Property/Logging`, log).then((response) => {
        return result;
    });    
    
}

export default logger