import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import allActions from '../../../../../../../Services/Store/Actions';
import { useTranslation } from 'react-i18next';
export default function Gift(props) {
    const dispatch = useDispatch();
    const [t, i18n] = useTranslation();
    const decrement = () => {
        dispatch(allActions.giftActions.decrementCurrentGift(props.value));
    }

    const increment = () => {
        dispatch(allActions.giftActions.incrementCurrentGift(props.value));
    }

    const remove = () => {
        dispatch(allActions.giftActions.removeFromSelectedGifts(props.value));
    }

    return (
        <div className="cards__item">
            <div className="item__amount">
                <button className="amount__change decrement" onClick={decrement}>-</button>
                <div className="amount__value"><span>{props.amount}</span></div>
                <button className="amount__change increment" onClick={increment}>+</button>
            </div>
            <div className="item__title">
                {t('Shared.Giftcard')} {props.currency + ' ' + props.value}
            </div>
            {props.isLast ? 
                <div className="item__actions">
                    <FontAwesomeIcon icon={["fal", "trash-alt"]} onClick={remove} />
                </div> : 
                <div className="item__actions"></div>

}
          
        </div>
    )
}