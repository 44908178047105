import { combineReducers } from 'redux';

import availableGifts from './_availableGifts';
import currentGifts from './_currentGifts';
import currentProperty from './_currentProperty';
import formGift from './_formGift';
import giftCardValues from './_giftCardValues';
import progress from './_progress';
import propertySettings from './_propertySettings';
import selectedGift from './_selectedGift';
import totalCost from './_totalCost';
import paymentData from './_paymentData';
import completePurchase from './_completePurchase';
import loading from './_loading';

const rootReducer = combineReducers({
    availableGifts,
    currentGifts,
    currentProperty,
    formGift,
    giftCardValues,
    progress,
    propertySettings,
    selectedGift,
    totalCost,
    paymentData,
    completePurchase,
    loading
});

export default rootReducer;